import React from "react";
import BaseLayout from "../../components/layouts/baseLayout";
import TextJumbotron from "../../components/util/textJumbotron";
import { Container, Row, Col } from "react-bootstrap";
import ChildWithColoredHandsImage from "../../components/images/childWithColoredHands";
export default function AcornsPage({ data }) {
  return (
    <BaseLayout pageTitle="Acorns Preschool Class">
      <TextJumbotron heading="THE ACORNS PROGRAM" body="(3-YEAR-OLD)" />
      <Container>
        <Row>
          <Col>
            <p className="lead font-weight-bold text-center">
              The Acorns program is for children who are 3 years old by December
              31.
            </p>
            <p>
              Our program provides a wide variety of activities to develop the
              interests of 3 year olds in a loving and nurturing environment.
              Our program reinforces colours, numbers, shapes, words, speech,
              and ideas. Children will be encouraged to learn through play to
              reach their full potential socially, intellectually, physically,
              and emotionally. Younger children learn through their senses, thus
              we have a wide variety of sensory activities. The children are
              exposed to many different songs, finger plays and books, which
              enhances language. Classes are designed to enrich both the mind
              and character of every child within an environment rich in fun,
              language instruction and self esteem building activities.
            </p>
          </Col>
        </Row>
        <Row className="pt-3">
          <Col xs={12} md={6}>
            {" "}
            <ChildWithColoredHandsImage className="rounded" />
          </Col>
          <Col xs={12} md={6} className="">
            <h2 className="text-center">Class Highlights </h2>
            <ul>
              <li>Two-week themes</li>
              <li>Arts, crafts, puzzles</li>
              <li> Show and share</li>
              <li> Circle time with themed story time</li>
              <li> Gross motor activity daily</li>
              <li> Centres daily</li>
              <li> Letter recognition/sounds</li>
              <li> Calendar and weather activities</li>
              <li> Field Trip</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </BaseLayout>
  );
}
